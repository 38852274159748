<template>
  <div class="page-container">
    <!-- <Product v-if="showProduct" :goToNotes="goToNotes" :setSelectedNote="setSelectedNote" :setSelectedUser="setSelectedUser" :toggleShowChat="toggleShowChat" :inWindow="false" :productId="productId" :closeProduct="closeProduct"/> -->
    <div v-if="showTutorial">
      <div id="roomoverview" class="content" style="z-index: 99999" @click="finishTutorial()">
        <div class="row row-no-padding align-items-center p-0 box" style="overflow: hidden;">
          <div class="chat-content w-100 mx-lg-4 mx-sm-0" style="max-height: 500px; min-height: 0px !important; overflow: auto;">
            <div class="chatbox row m-0">
              <button type="button" class="close" aria-label="Close" @click="finishTutorial()">
            <span aria-hidden="true" class="pull-right">
              <img src="@/assets/images/Icon-Close.svg" alt="" class="close--icon">
            </span>
              </button>
              <div class="col-md-8 right-side">
                <!-- Sadly, we need to add hard-coded text here. Programmer is unhappy :/.
                <div class="row mt-5">
                  <div v-for="(exhibit, i) in exhibitlist" :key="i" class="col-md-6 tutorial-text mb-5"><span class="tutorial-underscore"><img src="@/assets/images/Icon-underlined-low.svg"></span>&nbsp;&nbsp; {{exhibit}} </div>
                </div>-->
                <div id="intro-empfang" style="display: none;">
                  <h2 class="main-title" style="text-transform: uppercase;">Herzlich Willkommen auf der digitalen Häfele Plattform.<br> Entdecken Sie mit uns neue Wege, die Häfele <br>Highlights der Holz-Handwerk 2020 kennenzulernen.</h2>
                  <div class="row mt-5">
                    <div class="col-md-6 tutorial-text mb-5"><span class="tutorial-underscore"><img src="@/assets/images/Icon-underlined-low.svg"></span>&nbsp;&nbsp; Erklär-Video über die Funktionalitäten  der digitalen Plattform </div>
                  </div>
                </div>

                <div id="intro-kueche" style="display: none;">
                  <h2 class="main-title" style="text-transform: uppercase;">Häfele bietet Ihnen starke Sortimente.<br> In diesem Bereich bekommen Sie einen <br> umfassenden Überblick über die <br> Highlights für die Küche.</h2>
                  <div class="row mt-5">
                    <div class="col-md-6 tutorial-text mb-5"><span class="tutorial-underscore"><img src="@/assets/images/Icon-underlined-low.svg"></span>&nbsp;&nbsp; Komplette Küchensortimente in zwei Varianten </div>
                    <div class="col-md-6 tutorial-text mb-5"><span class="tutorial-underscore"><img src="@/assets/images/Icon-underlined-low.svg"></span>&nbsp;&nbsp; Klappenbeschläge Häfele Free </div>
                    <div class="col-md-6 tutorial-text mb-5"><span class="tutorial-underscore"><img src="@/assets/images/Icon-underlined-low.svg"></span>&nbsp;&nbsp; Häfele Drahtwarensortiment </div>
                    <div class="col-md-6 tutorial-text mb-5"><span class="tutorial-underscore"><img src="@/assets/images/Icon-underlined-low.svg"></span>&nbsp;&nbsp; Schubkästen Häfele Matrix Box </div>
                  </div>
                </div>

                <div id="intro-kuecheninnovationen" style="display: none;">
                  <h2 class="main-title" style="text-transform: uppercase;">Profitieren Sie von innovativen Beschlaglösungen für die Küche <br> (und auch in anderen Räumen).</h2>
                  <div class="row mt-5">
                    <div class="col-md-6 tutorial-text mb-5"><span class="tutorial-underscore"><img src="@/assets/images/Icon-underlined-low.svg"></span>&nbsp;&nbsp; Klappenbeschlag Häfele Free space </div>
                    <div class="col-md-6 tutorial-text mb-5"><span class="tutorial-underscore"><img src="@/assets/images/Icon-underlined-low.svg"></span>&nbsp;&nbsp; Spritzschutz- und Wandplatte Häfele Alusplash </div>
                    <div class="col-md-6 tutorial-text mb-5"><span class="tutorial-underscore"><img src="@/assets/images/Icon-underlined-low.svg"></span>&nbsp;&nbsp; Sockelverstellsystem Axilo mit Schallentkopplung </div>
                  </div>
                </div>

                <div id="intro-neuewege" style="display: none;">
                  <h2 class="main-title" style="text-transform: uppercase;">Nutzen Sie neue Möglichkeiten <br> für die Ausstattung von Schränken <br> und den Bau von Möbeln.</h2>
                  <div class="row mt-5">
                    <div class="col-md-6 tutorial-text mb-5"><span class="tutorial-underscore"><img src="@/assets/images/Icon-underlined-low.svg"></span>&nbsp;&nbsp; Schrank-Ausstattungssystem / Häfele Dresscode </div>
                    <div class="col-md-6 tutorial-text mb-5"><span class="tutorial-underscore"><img src="@/assets/images/Icon-underlined-low.svg"></span>&nbsp;&nbsp; Rahmensystem für Möbel / Häfele Versatile </div>
                  </div>
                </div>

                <div id="intro-schubkasten" style="display: none;">
                  <h2 class="main-title" style="text-transform: uppercase;">Entdecken Sie die vielseitigen <br> Möglichkeiten für Schubkästen.<br> Machen Sie sich die Arbeit <br> leicht und sparen Sie Zeit mit <br> Komplettschubkästen.</h2>
                  <div class="row mt-5">
                    <div class="col-md-6 tutorial-text mb-5"><span class="tutorial-underscore"><img src="@/assets/images/Icon-underlined-low.svg"></span>&nbsp;&nbsp; Schubkastensystem Häfele Matrix Box P </div>
                    <div class="col-md-6 tutorial-text mb-5"><span class="tutorial-underscore"><img src="@/assets/images/Icon-underlined-low.svg"></span>&nbsp;&nbsp; Schubkastensystem Häfele Matrix Box Slim A </div>
                    <div class="col-md-6 tutorial-text mb-5"><span class="tutorial-underscore"><img src="@/assets/images/Icon-underlined-low.svg"></span>&nbsp;&nbsp; Komplettschubkasten Häfele Matrix pro Medical </div>
                    <div class="col-md-6 tutorial-text mb-5"><span class="tutorial-underscore"><img src="@/assets/images/Icon-underlined-low.svg"></span>&nbsp;&nbsp; Häfele Komplettschubkästen aus Vollholz / MDF / Metall </div>
                  </div>
                </div>

                <div id="intro-akustik" style="display: none;">
                  <h2 class="main-title" style="text-transform: uppercase;">Eröffnen Sie sich neue Geschäftsfelder.<br> Häfele bietet dafür hochwertige Lösungen<br> für die Umsetzung einer angenehmen<br> Raumakustik an.</h2>
                  <div class="row mt-5">
                    <div class="col-md-6 tutorial-text mb-5"><span class="tutorial-underscore"><img src="@/assets/images/Icon-underlined-low.svg"></span>&nbsp;&nbsp; Raumakustik – physikalische Grundlagen </div>
                    <div class="col-md-6 tutorial-text mb-5"><span class="tutorial-underscore"><img src="@/assets/images/Icon-underlined-low.svg"></span>&nbsp;&nbsp; Decken- und Wandabsorber Rossoacoustic PAD-System </div>
                    <div class="col-md-6 tutorial-text mb-5"><span class="tutorial-underscore"><img src="@/assets/images/Icon-underlined-low.svg"></span>&nbsp;&nbsp; Schalldämmende Raumgliederungssystem Rossoacoustic TP 30 Silence und CP 30 </div>
                    <div class="col-md-6 tutorial-text mb-5"><span class="tutorial-underscore"><img src="@/assets/images/Icon-underlined-low.svg"></span>&nbsp;&nbsp; Referenzen </div>
                  </div>
                </div>

                <div id="intro-licht" style="display: none;">
                  <h2 class="main-title" style="text-transform: uppercase;">Entdecken Sie die neue Evolutionsstufe des<br> Häfele LED-Lichtsystems Loox5. Dazu finden Sie<br> in diesem Bereich neue Lösungen, die Ihre<br> Lichtprojekte vereinfachen und sogar eine<br> spezielle von Häfele entwickelte Leuchte für<br> den Möbelbau.</h2>
                  <div class="row mt-5">
                    <div class="col-md-6 tutorial-text mb-5"><span class="tutorial-underscore"><img src="@/assets/images/Icon-underlined-low.svg"></span>&nbsp;&nbsp; Einsatz von Möbellicht / Grundlagen </div>
                    <div class="col-md-6 tutorial-text mb-5"><span class="tutorial-underscore"><img src="@/assets/images/Icon-underlined-low.svg"></span>&nbsp;&nbsp; Vorkonfektionierte Linearleuchten </div>
                    <div class="col-md-6 tutorial-text mb-5"><span class="tutorial-underscore"><img src="@/assets/images/Icon-underlined-low.svg"></span>&nbsp;&nbsp; LED-System Loox5 inkl. Komponenten </div>
                    <div class="col-md-6 tutorial-text mb-5"><span class="tutorial-underscore"><img src="@/assets/images/Icon-underlined-low.svg"></span>&nbsp;&nbsp; Häfele Connect Mesh </div>
                    <div class="col-md-6 tutorial-text mb-5"><span class="tutorial-underscore"><img src="@/assets/images/Icon-underlined-low.svg"></span>&nbsp;&nbsp; Adapter zur Verbindung von Häfele Loox und Loox5 </div>
                    <div class="col-md-6 tutorial-text mb-5"><span class="tutorial-underscore"><img src="@/assets/images/Icon-underlined-low.svg"></span>&nbsp;&nbsp; Häfele Linearlinse </div>
                    <div class="col-md-6 tutorial-text mb-5"><span class="tutorial-underscore"><img src="@/assets/images/Icon-underlined-low.svg"></span>&nbsp;&nbsp; Beispielhafte Einbausituationen mit Verkabelung </div>
                  </div>
                </div>

                <div id="intro-schnellschoen" style="display: none;">
                  <h2 class="main-title" style="text-transform: uppercase;">Nutzen Sie neue Häfele Lösungen<br> für Ihren Möbelbau.</h2>
                  <div class="row mt-5">
                    <div class="col-md-6 tutorial-text mb-5"><span class="tutorial-underscore"><img src="@/assets/images/Icon-underlined-low.svg"></span>&nbsp;&nbsp; Batteriebetriebenes Möbelschloss Dialock EFL 30 </div>
                    <div class="col-md-6 tutorial-text mb-5"><span class="tutorial-underscore"><img src="@/assets/images/Icon-underlined-low.svg"></span>&nbsp;&nbsp; Häfele Ixconnect Krallenverbinder und Spreizverbinder SC 8/25 </div>
                    <div class="col-md-6 tutorial-text mb-5"><span class="tutorial-underscore"><img src="@/assets/images/Icon-underlined-low.svg"></span>&nbsp;&nbsp; Häfele Klappbettsystem </div>
                  </div>
                </div>

                <div id="intro-designakzente" style="display: none;">
                  <h2 class="main-title" style="text-transform: uppercase;">Greifen Sie zu und setzen Sie<br> phantastische Akzente mit neuen<br> Möbelgriff-Kollektionen von Häfele.</h2>
                  <div class="row mt-5">
                    <div class="col-md-6 tutorial-text mb-5"><span class="tutorial-underscore"><img src="@/assets/images/Icon-underlined-low.svg"></span>&nbsp;&nbsp; Häfele Möbelgriff-Kollektion H19 </div>
                    <div class="col-md-6 tutorial-text mb-5"><span class="tutorial-underscore"><img src="@/assets/images/Icon-underlined-low.svg"></span>&nbsp;&nbsp; Möbelgriffe / Design-Klassiker exklusiv bei Häfele </div>
                  </div>
                </div>


                <div class="d-flex flex-row align-items-center input-container">
                </div>
              </div>
              <hr class="mobile-seperator" />
              <div class="col-md-4 left-side" >
                <h2 class="main-title">WIE NAVIGIEREN<br>SIE RICHTIG IN 360°</h2>
                <div class="row mt-5">
                  <div class="col-md-9 tutorial-text"><p style="margin-left: 0 !important;">Sie befinden sich in einer 360° Ansicht</p></div>
                  <div class="col-md-12 mt-4"></div>
                  <div class="col-md-9 tutorial-text"><p style="margin-left: 0 !important;">Um in diesem Raum zu navigieren, klicken oder tippen Sie ins Bild und bewegen Sie bei gedrückter Maustaste die Maus nach links oder rechts, um sich umzusehen.</p></div>
                  <div class="col-md-3"> <img src="@/assets/images/Icon-tourn-panonav.svg" ></div>
                  <div class="col-md-12 mt-4"></div>
                  <div class="col-md-9 tutorial-text"><p style="margin-left: 0 !important;">Durch Scrollen können Sie die Zoom-Funktion aktivieren</p></div>
                  <div class="col-md-3"> <img src="@/assets/images/Icon-scroll-panonav.svg" ></div>
                  <div class="col-md-12 mt-4"></div>
                  <div class="col-md-9 tutorial-text"><p style="margin-left: 0 !important;">Die rot markierten Klickflächen können Sie durch einfaches Anklicken auslösen, um Detailinhalte abzurufen.</p> </div>
                  <div class="col-md-3"> <img src="@/assets/images/Icon-klick-panonav.svg" ></div>
                  <div class="col-md-3"></div>
                </div>
                <div class="row">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content black h-100" id="response"></div>
  </div>
</template>

<script>
// import Product from '../components/shared/Product.vue';

export default {
  name: 'Krpano',
  components: {
    // Product
  },
  props: {
    toggleShowChat: Function,
    setSelectedUser: Function,
    closeProduct: Function,
    showProduct: Boolean,
    toggleShowProduct: Function,
    selectedNote: String,
    setSelectedNote: Function,
    goToNotes: Function,
    selectedExhibit: Object,
    setSelectedExhibit: Function,
    closeNote: Function
  },
  data() {
    return {
      booth_uuid: this.$route.params.booth_uuid,
      booth_info: '',
      user_uuid: '',
      showTutorial: false,
      exhibitlist: []
    };
  },
  mounted() {
    this.changeBackBtnText();
    this.getAllExhibits();
    this.getUser();
    window.exhibitCallback = uuid => {
      this.setSelectedExhibit({ uuid: uuid });
      this.toggleShowProduct();
    };
  },
  async beforeDestroy() {
    let data = {
      uuid: -1
    };
    try {
      await this.$http.patch('/user', JSON.stringify(data));
    } catch (e) {
      console.log(e);
    }
  },
  methods: {
    async getAllExhibits() {
      let response = await this.$http.get('/exhibits', { params: { uuid: this.booth_uuid}});
      this.exhibitlist = response.data.map(exhibit=>exhibit.title);
      //console.log(this.exhibitslist);
    },
    changeBackBtnText() {
      document.getElementById('backbtn').innerHTML = '<i class="fas fa-angle-left mr-2"></i> Zurück ins Hauptmenü';
    },
    showTutorialInfo(){
      if(this.$route.params.booth_uuid=='5a23e400-ab01-11ea-8fdc-4f727217e998'){document.getElementById('intro-empfang').style.display = 'block';}
      else if (this.$route.params.booth_uuid=='65b63b60-ab01-11ea-8fdc-4f727217e998'){document.getElementById('intro-kueche').style.display = 'block';}
      else if(this.$route.params.booth_uuid=='6a926f00-ab01-11ea-8fdc-4f727217e998'){document.getElementById('intro-kuecheninnovationen').style.display = 'block';}
      else if(this.$route.params.booth_uuid=='6edfd020-ab01-11ea-8fdc-4f727217e998'){document.getElementById('intro-neuewege').style.display = 'block';}
      else if(this.$route.params.booth_uuid=='74196600-ab01-11ea-8fdc-4f727217e998'){document.getElementById('intro-schubkasten').style.display = 'block';}
      else if(this.$route.params.booth_uuid=='776c88a0-ab01-11ea-8fdc-4f727217e998'){document.getElementById('intro-akustik').style.display = 'block';}
      else if(this.$route.params.booth_uuid=='7efc6a90-ab01-11ea-8fdc-4f727217e998'){document.getElementById('intro-licht').style.display = 'block';}
      else if(this.$route.params.booth_uuid=='8841ebc0-ab01-11ea-8fdc-4f727217e998'){document.getElementById('intro-schnellschoen').style.display = 'block';}
      else if(this.$route.params.booth_uuid=='8ee17a40-ab01-11ea-8fdc-4f727217e998'){document.getElementById('intro-designakzente').style.display = 'block';}
    },
    getUser() {
      this.$http
        .get('/user')
        .then(resp => {
          this.user_uuid = resp.data.uuid;
          this.showTutorial = true; //!resp.data.tutorial_completed;
          this.getBoothInfo(this.booth_uuid);
          setTimeout(
            function() {
              this.showTutorialInfo();
            }.bind(this),
            300
          );
          
        })
        .catch(err => {
          console.log(err);
        });
    },
    addHeader(URL) {
      let jitsi = document.createElement('script');
      jitsi.setAttribute('src', URL);
      document.head.appendChild(jitsi);
    },
    getBoothInfo(id) {
      this.$http
        .get('/booth', {
          params: {
            uuid: id
          }
        })
        .then(resp => {
          this.getKrpano(resp.data.krpano_path);
        })
        .catch(err => {
          console.log(err);
        });
    },
    getKrpano(krpano_path) {
      this.addHeader(krpano_path + 'tour.js');
      this.startKrpano(krpano_path);
    },
    startKrpano(krpano_path) {
      try {
        window.embedpano({
          swf: krpano_path + 'tour.swf',
          xml: krpano_path + 'tour.xml',
          target: 'response',
          html5: 'auto',
          mobilescale: 1.0,
          id: this.user_uuid,
          passQueryParameters: true
        });
      } catch {
        setTimeout(
          function() {
            this.startKrpano(krpano_path);
          }.bind(this),
          500
        );
      }
    },
    finishTutorial() {
      this.showTutorial = false;
      this.$http
        .patch('/tutorial')
        .then(() => {})
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style scoped>
body,
html {
  height: 100%;
}

.main-title{
  font-family: Roboto, sans-serif !important;
  color: #f7eaa4;
}

.tutorial-text{
  color:white;
}

.tutorial-product{

}

.tutorial-underscore{
  padding-top: -5px;
}

.content {
  margin: 0px;
  padding-left: 0px !important;
  padding-right: 0px !important;
  width: 100%;
  height: calc(100vh - 120px) !important;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.page-container {
  margin: 0px;
  width: 100%;
  height: calc(100vh - 120px);
  padding-left: 0px !important;
  padding-right: 0px !important;
  position: relative;
}

.popup-container {
  position: fixed;
  z-index: 150;
  width: 100%;
  height: 100vh;
  margin-top: -70px;
}

.inbox-container {
  width: 100%;
  height: 25%;
  padding: 10%;
  text-align: left;
}

.card-text {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.49px;
  background-color: #000000cc;
}

p {
  margin-left: 2.5%;
  width: 70%;
}

.start-button {
  margin: auto auto;
  background: white;
  width: 95%;
  font-size: 16px;
  min-height: 37px;
  color: black;
  text-align: left;
  display: block;
  border: 0;
}

.start-button:hover {
  background-color: rgba(210, 10, 40, 1);
  cursor: pointer;
}

.start-button span {
  margin-left: 5%;
}

.close {
  position: absolute;
  font-size: 20px;
  right: 50px;
  top: 50px;
  z-index: 112;
  color: white;
  opacity: 1 !important;
  font-weight: 100;
  transform: translate(-50%, -50%);

  &--icon{
  width: 14px;
 }
}

/* Room overview modal */

.mobile-seperator {
  display: none;
}

.box {
  z-index: 112;
  position: absolute;
  bottom: 0;
  width: 98% !important;
  margin-left: 1% !important;
  margin-right: 1% !important;
}
.chat-content {
  background-color: #000000cc;
  padding: 75px !important;
  min-height: 60vh;
  position: relative;
}

.right-side {
  max-height: 60vh;
}

.messages-container {
  margin-left: 20px !important;
  margin-right: 20px !important;
  padding-left: 0 !important;
  padding-right: 15px !important;
  overflow-y: auto;
  scrollbar-color: rgba(210, 10, 40, 1) rgba(140, 35, 50, 1);
  scrollbar-width: thin;
  margin-top: 20px;
  height: 81%;
  height:450px;
  font-size: 0.75rem;
  overflow: scroll!important;
}

.input-container {
  margin-left: 20px !important;
  margin-right: 20px !important;
  padding-left: 0 !important;
  padding-right: 15px !important;
  height: 12%;
}


/* #### Mobile Phones Portrait #### */
@media screen and (max-device-width: 480px) and (orientation: portrait) {
  .inbox-container {
    margin: 30% auto;
  }
}

/* #### Mobile Phones Landscape #### */
@media screen and (max-device-width: 640px) and (orientation: landscape) {
  .inbox-container {
    margin: 5% auto;
    width: 80%;
  }
}
</style>
